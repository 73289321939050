import CardRail from '../CardRail';
import ThemeCard from '../ThemeCard';

import type { HotelsByStarRatingProps } from 'common-types/types/HotelsByStarRatingProps';

const HotelsByStarRating = ({
  cards,
  subtitle,
  title,
}: HotelsByStarRatingProps) => {
  if (!cards.length) {
    return null;
  }

  return (
    <div className="HotelsByStarRating">
      <CardRail title={title} subtitle={subtitle}>
        {cards.map((card) => (
          <ThemeCard key={card.title} {...card} />
        ))}
      </CardRail>
    </div>
  );
};

export default HotelsByStarRating;
